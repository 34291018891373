define(
  ({
    new_order_document: 'Zamówienie Elastic',
    your_order_document: 'Twoje zamówienie',
    no_quantities: 'Musisz mieć wybraną ilość co najmniej 1, aby złożyć zamówienie.',
    no_customer_number: 'Wybierz adres rozliczeniowy dla ',
    no_location_number: 'Wybierz adres wysyłki dla ',
    no_arrival_date: 'Wybierz datę rozpoczęcia wysyłki dla',
    no_cancel_date: 'Wybierz datę anulowania dla',
    invalid_cancel_date: 'Wybierz prawidłową datę anulowania.',
    invalid_start_ship_date: 'Wybierz prawidłową datę rozpoczęcia wysyłki dla',
    no_po_number: 'Wprowadź numer zamówienia dla',
    po_number_exceeds_limit: 'Numer PO dla ### przekracza limit ###### znaków.',
    po_number_duplicate: 'Numery zamówień muszą być niepowtarzalne dla każdego zamówienia.',
    invalid_po_number: 'Proszę podać poprawny numer zamówienia',
    invalid_page_comment: 'Prosimy o wpisanie poprawnego komentarza',
    products_not_available: 'Masz w koszyku produkty, które nie będą dostępne przed wybraną datą wysyłki.',
    already_submitted: 'To zamówienie zostało już przesłane.',
    already_review: 'To zamówienie zostało już przesłane do przedstawiciela, musi zostać sprawdzone.',
    shared_with: 'Dokument został udostępniony sprzedawcy. Jest tylko do odczytu i nie można go przesłać',
    dealer_submit: 'Dokument oczekuje na sprawdzenie przez Twojego przedstawiciela i nie można go przesłać',
    page: 'Strona',
    shipment: 'Wysyłka',
    external_field: 'Wybierz ### dla ',
    credit_hold_explanation: 'Przy obecnym statusie konta możesz utworzyć zamówienie, ale nie możesz go przesłać. Skontaktuj się ze swoim menedżerem ds. Kredytów.',
    catalog_min_failure: 'Musi mieć co najmniej ### jednostki(-ek) dla tego katalogu.',
    catalog_max_failure: 'Przekroczono limit ### jednostek dla tego katalogu.',
    catalog_min_amount_failure: 'Minimalna łączna kwota zamówienia dla tego katalogu wynosi ###.',
    catalog_max_amount_failure: 'Maksymalna łączna kwota zamówienia dla tego katalogu wynosi ###.',
    catalog_price_group_failure: 'Nie można złożyć zamówienia z powodu brakującej grupy cenowej.',
    catalog_requires_embellishments: 'Wszystkie produkty muszą być spersonalizowane.',
    catalog_requires_embellishments_customized: 'Wszystkie produkty muszą być spersonalizowane.',
    arrive_on_is_in_past: 'Data rozpoczęcia wysyłki przypada w przeszłości dla ###. Zaktualizuj ją tak, aby wypadała dziś lub w przyszłości.',
    shipping_account_number_invalid: 'Podaj prawidłowy numer konta wysyłkowego',
    document_renamed: 'Zmieniono nazwę zamówienia.',
    document_could_not_be_renamed: 'Nie można zmienić nazwy zamówienia.',
    has_placeholder_allocations: 'Niektóre produkty nie są dostępne w żądanej dacie wysyłki. Aby kontynuować, ten problem musi zostać rozwiązany.',
    shipment_min_amount_failure: 'Minimalna łączna kwota na przesyłkę to ###',
    shipment_min_quantity_failure: 'Minimalna całkowita ilość na przesyłkę to ### sztuk.',
    shipment_max_amount_failure: 'Maksymalna łączna kwota na przesyłkę to ###',
    shipment_max_quantity_failure: 'Maksymalna ilość na przesyłkę to ### sztuk.',
    order_has_shipments_with_state_restrictions: 'Zamówienie ma przesyłki z ograniczeniami stanowymi',
    shipment_summary: 'Podsumowanie wysyłki',
    choose_location: 'Wybierz lokalizację',
    save_order_title: 'Czy chcesz zapisać swoje obecne zamówienie?',
    save_order_msg: 'Masz obecnie inne otwarte zamówienie. Chcesz zapisać to zamówienie jako szkic i otworzyć to nowe?',
  }),
);

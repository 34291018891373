define(
  ({
    new_order_document: 'Elastic bestilling',
    your_order_document: 'Bestillingen din',
    no_quantities: 'Du må ha et valgt antall på minst 1 for å kunne sende inn en bestilling.',
    no_customer_number: 'Velg en faktureringsadresse for ',
    no_location_number: 'Velg en leveringsadresse for ',
    no_arrival_date: 'Velg en startdato for',
    no_cancel_date: 'Velg en avbestillingsdato for',
    invalid_cancel_date: 'Velg en gyldig kanselleringsdato.',
    invalid_start_ship_date: 'Velg en gyldig startdato for',
    no_po_number: 'Angi PO-nummer for',
    po_number_exceeds_limit: 'PO-nummeret for ### overstiger grensen på ###### tegn.',
    po_number_duplicate: 'PO-numre må være unike for hver bestilling.',
    invalid_po_number: 'Skriv inn et gyldig PO-nummer',
    invalid_page_comment: 'Skriv inn en gyldig kommentar',
    products_not_available: 'Du har produkter i handlekurven din som ikke vil være tilgjengelig innen den valgte leveringsdatoen.',
    already_submitted: 'Denne bestillingen er allerede sendt.',
    already_review: 'Du har allerede sendt denne bestillingen til representanten din, men den må gjennomgås.',
    shared_with: 'Dokumentet har blitt delt med en forhandler. Den er skrivebeskyttet og kan ikke sendes inn',
    dealer_submit: 'Dokumentet venter på at din representant skal gjennomgå det, og kan ikke sendes inn',
    page: 'Side',
    shipment: 'Forsendelse',
    external_field: 'Velg ### for ',
    credit_hold_explanation: 'Med gjeldene kontostatus kan du opprette en bestilling, men du kan ikke sende inn. Ta kontakt med kredittansvarlig.',
    catalog_min_failure: 'Må ha minst ### enheter for denne katalogen.',
    catalog_max_failure: 'Overskred grensen på ### enheter for denne katalogen.',
    catalog_min_amount_failure: 'Minste bestillingsbeløp for denne katalogen er ###.',
    catalog_max_amount_failure: 'Maks totalbeløp for denne katalogen er ###.',
    catalog_price_group_failure: 'Bestillingen kan ikke legges inn på grunn av manglende prisgruppe.',
    catalog_requires_embellishments: 'Alle varene må tilpasses.',
    catalog_requires_embellishments_customized: 'Alle varene må tilpasses.',
    arrive_on_is_in_past: 'Start forsendelsedato er tidligere for ###. Oppdater dette slik at det er i dag eller i fremtiden.',
    shipping_account_number_invalid: 'Angi et gyldig fraktkontonummer',
    document_renamed: 'Ordren fikk nytt navn.',
    document_could_not_be_renamed: 'Kunne ikke endre ordrenavn',
    has_placeholder_allocations: 'Noen varer er ikke tilgjengelige innen den forespurte forsendelsesdatoen. For å fortsette må dette problemet løses.',
    shipment_min_amount_failure: 'Minste totale beløp per forsendelse er ###',
    shipment_min_quantity_failure: 'Minimum total mengde per forsendelse er ### enheter.',
    shipment_max_amount_failure: 'Maksimalt totalbeløp per forsendelse er ###',
    shipment_max_quantity_failure: 'Maks total mengde per forsendelse er ### enheter.',
    order_has_shipments_with_state_restrictions: 'Bestillingen har forsendelser med statlige restriksjoner',
    shipment_summary: 'Forsendelsesoversikt',
    choose_location: 'Velg lokasjon',
    save_order_title: 'Vil du lagre den nåværende bestillingen?',
    save_order_msg: 'Du har en annen bestilling åpen nå. Vil du lagre denne bestillingen som utkast og åpne denne?',
  }),
);

define(
  ({
    app_name: 'Elastic',
    none: 'Aucun',
    select: 'sélectionner…',
    cancel: 'Annuler',
    drop_ship_instructions: "Expédition intérieure uniquement. Pas d'envoi vers les bureaux de poste militaires ou navals.",
    product_data_sheet_tab_technology: 'Technologie',
    product_data_sheet_tab_overview: 'Description',
    product_data_sheet_tab_details: 'Détails',
    cart_submit_order_dealer: 'Passer la commande',
    cart_submit_order_rep: 'Passer la commande',
    cart_submit_order: 'Soumettre la command',
    erp_order_number: 'Nº de Commande PGI',
    sap_order_number: 'Commande SAP n°',
    nda_explanation: 'Explication LDN.',
    terms_and_conditions_description: 'Acceptez ci-dessous pour indiquer que vous avez lu et accepté les <a href="###" target="_blank">Conditions Générales de Vente</a>',
    discounted_total: 'Total avec réduction',
    upcharge: 'Supplément',
    dont_show_me_this_again: 'Ne plus afficher ça.',
    okay: 'Ok',
    arrival_date: "Date de début de l'expédition",
    sso_message: 'Connection externe',
    alt_sso_message: 'Connexion externe alternative',
    alt_login_title: 'Se connecter',
    prebook: 'Pré-saison',
    ats: 'Réassort',
    alternate_images: 'Images alternatives',
    choose: 'Choisir',
    new_version_available_modal: 'Nouvelle version disponible',
    new_version_available_message: "Une nouvelle version de l'application est disponible",
    new_version_reload: 'Charger la nouvelle version',
    new_version_snooze: 'Me rappeler plus tard',
    new_version_save_and_reload: 'Enregistrer mon travail et recharger',
    popup_may_be_suppressed_title: 'Page de paiement ouverte',
    popup_may_be_suppressed_description: "Une page de paiement par carte bancaire a été ouverte. Si vous ne la voyez pas, cherchez une fenêtre pop-up dans la barre d'adresse de votre navigateur et autorisez-la.",
    popup_blocked_title: 'Pop-up bloqué',
    popup_blocked_description: "La nouvelle fenêtre d'envoi du paiement a été bloquée. Vérifiez les paramètres et les extensions de votre navigateur et réessayez. Si le problème persiste, vous pouvez enregistrer votre commande et ouvrir Elastic dans un autre navigateur. Elastic est mieux pris en charge dans les versions récentes de Google Chrome.",
    pay_by_credit_card: 'Payer par carte bancaire',
    do_you_want_to_pay_by_credit_card: 'Voulez-vous payer par carte bancaire ?',
    pay_by_credit_card_no: 'Non',
    pay_by_credit_card_yes: 'Oui',
    cancel_order_submission_and_payment: "Annuler l'envoi et le paiement de la commande",
    product_reference_short: 'RÉF : %{reference}',
    product_reference_long: "UGS de l'année précédente : %{reference}",
    variation_code: 'Code de variante',
    pending_orders: 'Commandes en attente',
    freight_discount_message: "Les articles de cette expédition sont éligibles à la réduction sur les frais d'expédition de %{sales_program}",
    partial_freight_discount_message: "Choisissez un mode d'expédition pour les articles de cette expédition qui ne sont pas éligibles à la réduction sur les frais d'expédition de %{sales_program}",
    non_qualifying_items_ship_by_default_method: 'Les articles qui ne sont pas éligibles au %{sales_program} seront expédiés via la méthode par défaut',
    export_pdf: 'Exporter en PDF',
    ots: 'Disponible à la vente',
    terms_and_conditions: "Conditions d'utilisation",
    accept: 'Accepter',
    back_to_orders: 'Retour aux commandes',
    back_to_payments: 'Retour aux paiements',
    back_to_shipments: 'Retours aux expéditions',
    required: 'Requis',
    send_request: 'envoyer une demande',
    should_not_empty: 'ne devrait pas être vide',
    should_be_number: 'devrait être un numéro',
    incorrect_value: 'valeur incorrecte',
    incorrect_phone: 'le numéro de téléphone devrait correspondre au format international +xx',
    board_riders_description: "Boardriders, Inc. est une société de premier plan spécialisée dans les sports d'action et le style de vie, qui conçoit, produit et distribue des vêtements, des chaussures et des accessoires de marque pour les adeptes du surf du monde entier. Les marques de vêtements et de chaussures de la société reflètent un style de vie décontracté pour les jeunes qui sont inspirés et passionnés par les sports extrêmes en plein air.",
    region_error_message: 'il faut choisir une région',
    country_error_message: 'il faut choisir un pays',
    state_error_message: 'il faut choisir une région/un département',
    config_error_message: "Une erreur est survenue lors de l'ouverture de ce formulaire à cause de l'absence de paramètres d'administration.",
    success: 'Succès',
    error: 'Erreur',
    invalid_phone_number: 'Numéro de téléphone non valide',
    invalid_email: 'adresse email invalide',
    submit: 'Soumettre',
    success_message: 'Vos informations ont été envoyées avec succès',
    error_message: "Il y a eu une erreur lors de l'envoi de vos informations. Veuillez contacter notre assistance si le problème persiste.",
    no_schema: "Cette fonctionnalité n'a pas été configurée",
    reset: 'Réinitialiser',
    quick_entry_table_header_shipment_b2c: 'Expédition',
    enter_quantities_segmented_control_b2c_by_shipment: 'Par expédition',
    new_document_b2c_shipment: 'Expédition',
    boxed_order: 'Commande en boîte',
    boxed_order_explanation_title: 'Commandes en boîte',
    boxed_order_explanation: "Ajouter plus d'une seule boîte à une commande fait office de multiplicateur de quantité. Les quantités saisies seront dupliquées à travers le nombre de boîtes saisi.",
    boxes: 'Boîtes',
    units_per_box: 'Unités par boîte',
    regional: 'Régional',
    purchase_order_tooltip: "La valeur saisie n'est pas valide.",
    tournament_date_is_too_late: 'La date doit être le %{date} ou avant',
    tournament_date_is_too_early: 'La date doit être le %{date} ou après celle-ci',
    page_notes_error: "La valeur saisie n'est pas valide.",
    order_name_not_valid: "La valeur saisie n'est pas valide.",
    internet_connection_lost: 'Connexion Internet perdue',
    internet_connection_lost_builder_description: "<p>Vous n'êtes pas connecté à Internet. Veuillez vous reconnecter pour continuer. Votre travail a été enregistré dans la dernière sauvegarde manuelle ou automatique.</p><p>Si vous craignez une perte de données, veuillez cliquer sur le bouton ci-dessous pour télécharger un fichier de sauvegarde et le transmettre à <Link>support@elasticsuite.com</Link> afin de rétablir votre commande.</p>",
    internet_connection_lost_description: "Vous n'êtes actuellement pas connecté à Internet. Veuillez vous reconnecter pour continuer.",
    download_backup_data_file: 'Télécharger le fichier de données de sauvegarde',
    invalid_session: 'Session non valide',
    invalid_session_builder_description: "<p>Vous n'êtes pas connecté à Internet. Veuillez vous reconnecter pour continuer. Votre travail a été enregistré dans la dernière sauvegarde manuelle ou automatique.</p><p>Si vous craignez une perte de données, veuillez cliquer sur le bouton ci-dessous pour télécharger un fichier de sauvegarde et le transmettre à <Link>support@elasticsuite.com</Link> afin de rétablir votre commande.</p>",
    invalid_session_description: "Vous n'êtes actuellement pas connecté à Internet. Veuillez vous reconnecter pour continuer.",
    submit_order_description: "Votre commande est sur le point d'être soumise. Une fois soumise, elle sera verrouillée et elle ne pourra plus être modifiée. Veuillez noter que la commande sera enregistrée sur le cloud.",
    all_sells_final: "Tous les commandes d'événement sont finales et impossible de les retourner. Veuillez examiner soigneusement votre commande avant de la soumettre. Merci de votre soutien !",
    invalid_date: 'Date invalide',
    date_placeholder: 'MM-DD-YYYY',
    recalculate_discounts: 'Recalculer les réductions',
    available_as_of_unrestricted: 'Disponible à partir du <date />',
    available_as_of: 'Disponible à partir du <date />',
    order_too_large_not_saved: 'Commande non enregistrée',
    order_too_large_description: 'La commande dépasse les limites maximales. Divisez la commande en plusieurs commandes.',
    access_denied: 'Accès refusé',
    deep_link_description: "Vous n'avez pas la permission d'accéder au lien partagé. Contactez votre administrateur de marque.",
    close: 'Fermer',
    sizes: 'Tailles',
    revisions: 'Révisions',
    technology: 'Technologie',
    description: 'Description',
    failed_to_find_prices: 'Échec de la recherche de prix.',
    availability: 'Disponibilité',
    available_by: 'Disponible le',
    total: 'Total',
    today: "Aujourd'hui",
    similar: 'Similaire',
    complementary: 'Complémentaire',
    previous: 'Précédent',
    next: 'Suivant',
    add_to_shipment: "Ajouter à l'expédition :",
    add_to_cart: 'Ajouter au panier',
    remove_from_cart: 'Retirer du panier',
    units_bundled_singular_label: '%{units} unité groupée',
    catalog_status: 'Produits actifs',
    dropped: 'Retiré',
    add_all: 'Ajouter toutes les variantes',
    in_cart: 'Dans le panier',
    not_in_cart: 'Pas dans le panier',
    add: 'Ajouter',
    remove: 'Retirer',
  }),
);

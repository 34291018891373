define(
  ({
    new_order_document: 'Commande Elastic',
    your_order_document: 'Votre commande',
    no_quantities: "Vous devez avoir une quantité sélectionnée d'au moins 1 pour pouvoir soumettre une commande.",
    no_customer_number: 'Veuillez sélectionner une adresse de facturation pour',
    no_location_number: 'Veuillez sélectionner une adresse de livraison pour',
    no_arrival_date: "Veuillez sélectionner une date de début de l'expédition pour",
    no_cancel_date: "Veuillez sélectionner une date d'annulation pour",
    invalid_cancel_date: "Veuillez sélectionner une date d'annulation valide.",
    invalid_start_ship_date: "Veuillez sélectionner une date d'expédition valide pour",
    no_po_number: 'Veuillez entrer un numéro de bon de commande',
    po_number_exceeds_limit: 'Le numéro de commande pour ### dépasse la limite de ###### caractères.',
    po_number_duplicate: 'Le numéro de bon de commande doit être unique pour chaque commande.',
    invalid_po_number: 'Veuillez entrer un numéro de bon de commande valide',
    invalid_page_comment: 'Veuillez saisir un commentaire valide',
    products_not_available: "Votre panier contient des produits qui ne seront pas disponibles à la date d'expédition que vous avez sélectionnée.",
    already_submitted: 'Cette commande a été déjà soumise.',
    already_review: 'Vous avez déjà envoyé cette commande à votre représentant, elle doit être examinée.',
    shared_with: 'Le document a été partagé avec un revendeur. Il est en lecture seule et ne peut pas être soumis.',
    dealer_submit: "Le document est en attente d'examen par votre représentant et ne peut pas être soumis.",
    page: 'Page',
    shipment: 'Livraison',
    external_field: 'Veuillez sélectionner ### pour ',
    credit_hold_explanation: 'Votre état de compte actuel vous permet de créer une commande, mais vous ne pourrez pas la soumettre. Veuillez contacter votre Manager de crédits.',
    catalog_min_failure: 'Il faut avoir au moins ### unités pour ce catalogue.',
    catalog_max_failure: 'Limite de ### unités pour ce catalogue dépassée.',
    catalog_min_amount_failure: 'Le montant de commande total minimum pour ce catalogue est de ###.',
    catalog_max_amount_failure: 'Le montant de commande total maximum pour ce catalogue est de ###.',
    catalog_price_group_failure: "Impossible de passer commande à cause de l'absence du groupe de prix.",
    catalog_requires_embellishments: 'Tous les éléments doivent être personnalisés.',
    catalog_requires_embellishments_customized: 'Tous les éléments doivent être personnalisés.',
    arrive_on_is_in_past: "La date de début de l'expédition est dans le passé pour ###. Veuillez mettre à jour celle-ci en la définissant sur aujourd'hui ou une date future.",
    shipping_account_number_invalid: "Veuillez saisir un numéro de compte d'expédition valide",
    document_renamed: 'Commande renommée.',
    document_could_not_be_renamed: 'Impossible de renommer la commande.',
    has_placeholder_allocations: "Certains éléments ne seront pas disponibles d'ici votre date d'expédition demandée. Vous devez résoudre ce problème pour continuer.",
    shipment_min_amount_failure: 'Le montant total minimum par envoi est ###',
    shipment_min_quantity_failure: 'La quantité totale minimale par envoi est de ### unités.',
    shipment_max_amount_failure: 'Le montant total maximum par envoi est ###',
    shipment_max_quantity_failure: 'La quantité totale maximale par envoi est de ### unités.',
    order_has_shipments_with_state_restrictions: "La commande a des expéditions avec des restrictions d'état",
    shipment_summary: 'Résumé de la livraison',
    choose_location: "Choisir l'emplacement",
    save_order_title: 'Voudriez-vous enregistrer votre commande actuelle ?',
    save_order_msg: 'Vous avez actuellement une autre commande ouverte. Voulez-vous enregistrer cette commande comme brouillon et ouvrir celle-ci ?',
  }),
);

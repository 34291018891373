define(
  ({
    new_order_document: 'Заказ Elastic',
    your_order_document: 'Ваш заказ',
    no_quantities: 'Для отправки заказа необходимо выбрать количество не менее 1.',
    no_customer_number: 'Выберите адрес выставления счетов для ',
    no_location_number: 'Выберите адрес доставки для ',
    no_arrival_date: 'Выберите дату отправки для',
    no_cancel_date: 'Выберите дату отмены для',
    invalid_cancel_date: 'Выберите допустимую дату отмены.',
    invalid_start_ship_date: 'Выберите допустимую дату отправки для',
    no_po_number: 'Введите номер заказа на поставку для',
    po_number_exceeds_limit: 'Номер заказа для ### превышает лимит в ###### символов.',
    po_number_duplicate: 'Номера заказов на поставку должны быть уникальными для каждого заказа.',
    invalid_po_number: 'Введите допустимый номер заказа на поставку',
    invalid_page_comment: 'Введите допустимый комментарий',
    products_not_available: 'В вашей корзине есть товары, которые не будут в наличии к выбранной дате отправки.',
    already_submitted: 'Этот заказ уже был отправлен.',
    already_review: 'Вы уже отправили этот заказ своему представителю. Его необходимо проверить.',
    shared_with: 'Дилеру предоставлен доступ к документу. Он предназначен только для чтения и не может быть отправлен',
    dealer_submit: 'Документ ожидает проверки со стороны вашего представителя и не может быть отправлен',
    page: 'Страница',
    shipment: 'Доставка',
    external_field: 'Выберите ### для ',
    credit_hold_explanation: 'При текущем статусе учетной записи вы можете создать заказ, но не можете отправить его. Свяжитесь со своим кредитным менеджером.',
    catalog_min_failure: 'Для этого каталога необходимо не менее ### ед.',
    catalog_max_failure: 'Превышено ограничение в размере ### позиций для данного каталога.',
    catalog_min_amount_failure: 'Минимальная общая сумма заказа для этого каталога — ###.',
    catalog_max_amount_failure: 'Максимальная общая сумма заказа для этого каталога — ###.',
    catalog_price_group_failure: 'Невозможно разместить заказ из-за отсутствия ценовой группы.',
    catalog_requires_embellishments: 'Все предметы необходимо персонализировать.',
    catalog_requires_embellishments_customized: 'Все предметы необходимо персонализировать.',
    arrive_on_is_in_past: 'Дата начала отправки для ### находится в прошлом . Установите текущую дату или дату в будущем.',
    shipping_account_number_invalid: 'Введите допустимый номер счета отправления',
    document_renamed: 'Заказ переименован.',
    document_could_not_be_renamed: 'Не удалось переименовать заказ.',
    has_placeholder_allocations: 'Некоторые позиции недоступны к запрашиваемой дате отправки. Устраните ошибку, чтобы продолжить.',
    shipment_min_amount_failure: 'Минимальная общая сумма за отправку составляет ###',
    shipment_min_quantity_failure: 'Минимальное общее количество на отправку составляет ### единиц.',
    shipment_max_amount_failure: 'Максимальная общая сумма за отправку составляет ###',
    shipment_max_quantity_failure: 'Максимальное общее количество на отправку составляет ### единиц.',
    order_has_shipments_with_state_restrictions: 'В заказе есть отправки с ограничениями по штатам',
    shipment_summary: 'Сводка по отправке',
    choose_location: 'Выбрать местоположение',
    save_order_title: 'Хотите сохранить ваш текущий заказ?',
    save_order_msg: 'У вас сейчас открыт другой заказ. Хотите сохранить тот заказ как черновик и открыть этот?',
  }),
);

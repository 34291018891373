define(
  ({
    new_order_document: 'Elastinen tilaus',
    your_order_document: 'Tilauksesi',
    no_quantities: 'Sinun on valittava vähintään 1 tilaus voidaksesi lähettää tilauksen.',
    no_customer_number: 'Valitse laskutusosoite kohteelle ',
    no_location_number: 'Valitse toimitusosoite kohteelle ',
    no_arrival_date: 'Valitse toimituksen aloituspäivä kohteelle',
    no_cancel_date: 'Valitse peruutuspäivä kohteelle',
    invalid_cancel_date: 'Valitse käypä peruutuspäivä.',
    invalid_start_ship_date: 'Valitse käypä toimituksen aloituspäivä kohteelle',
    no_po_number: 'Anna postinumero kohteelle',
    po_number_exceeds_limit: 'PO-numero ### on yli ###### merkin pituinen.',
    po_number_duplicate: 'Jokaisella tilauksella on oltava erilliset postinumerot.',
    invalid_po_number: 'Anna käypä postinumero',
    invalid_page_comment: 'Anna käypä kommentti',
    products_not_available: 'Kärryssäsi on tuotteita, jotka eivät ole saatavilla valitsemaasi toimituspäivään mennessä.',
    already_submitted: 'Tämä tilaus on jo lähetetty.',
    already_review: 'Olet jo lähettänyt tämän tilauksen edustajallesi. Se on tarkistettava.',
    shared_with: 'Asiakirja on jaettu myyjällesi. Se on ainoastaan luettavissa, eikä sitä voida lähettää.',
    dealer_submit: 'Asiakirja odottaa edustajasi tarkistusta, eikä sitä voida lähettää',
    page: 'Sivu',
    shipment: 'Toimitus',
    external_field: 'Valitse ### kohteelle ',
    credit_hold_explanation: 'Voit luoda tilauksen, mutta et voi lähettää sitä tilisi tämänhetkisen tilan vuoksi. Ota yhteyttä luottovastaavaasi.',
    catalog_min_failure: 'Tässä katalogissa on oltava vähintään ### yksikköä.',
    catalog_max_failure: 'Tämän katalogin ### yksikön rajoitus on ylitetty.',
    catalog_min_amount_failure: 'Tämän katalogin vähimmäismäärä on ###.',
    catalog_max_amount_failure: 'Tämän katalogin enimmäismäärä on ###.',
    catalog_price_group_failure: 'Tilausta ei voida tehdä puuttuvan hintaryhmän vuoksi.',
    catalog_requires_embellishments: 'Kaikki tuotteet on personalisoitava.',
    catalog_requires_embellishments_customized: 'Kaikki tuotteet on muokattava.',
    arrive_on_is_in_past: 'Tilauksen ### toimituksen aloituspäivä on jo mennyt. Muuta kyseinen päivämäärä tälle päivälle tai myöhemmäksi.',
    shipping_account_number_invalid: 'Anna käypä toimitustilin numero',
    document_renamed: 'Tilaus nimetty uudelleen.',
    document_could_not_be_renamed: 'Tilausta ei voitu nimetä uudelleen.',
    has_placeholder_allocations: 'Jotkin tuotteet eivät ole saatavilla pyydettyyn toimituspäivään mennessä. Tämä ongelma on selvitettävä ennen kuin voit jatkaa.',
    shipment_min_amount_failure: 'Toimituskohtainen vähimmäissumma on ###',
    shipment_min_quantity_failure: 'Toimituskohtainen vähimmäismäärä on ### yksikköä.',
    shipment_max_amount_failure: 'Toimituskohtainen maksimisumma on ###',
    shipment_max_quantity_failure: 'Toimituskohtainen maksimimäärä on ### yksikköä.',
    order_has_shipments_with_state_restrictions: 'Tilaus sisältää toimituksia, joilla on osavaltiokohtaisia rajoituksia',
    shipment_summary: 'Toimituksen yhteenveto',
    choose_location: 'Valitse sijainti',
    save_order_title: 'Haluatko tallentaa tämänhetkisen tilauksesi?',
    save_order_msg: 'Sinulla on tällä hetkellä erillinen avoin tilaus. Haluatko tallentaa kyseisen tilauksen vedoksena ja avata tämän?',
  }),
);

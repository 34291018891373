define(
  ({
    new_order_document: 'Elastik Sipariş',
    your_order_document: 'Siparişin',
    no_quantities: 'Bir sipariş göndermek için siparişte en az 1 miktarın seçilmesi gerekmektedir.',
    no_customer_number: 'Lütfen bir Fatura Adresi için seç:',
    no_location_number: 'Lütfen bir Gönderim Adresi seç:',
    no_arrival_date: 'Lütfen Başlangıç Gönderme Tarihi seç:',
    no_cancel_date: 'Lütfen bir İptal Etme Tarihi seç:',
    invalid_cancel_date: 'Lütfen geçerli bir iptal etme tarihi seç.',
    invalid_start_ship_date: 'Lütfen geçerli bir Başlangıç Gönderme Tarihi seç:',
    no_po_number: 'Lütfen bir PO Numarası gir:',
    po_number_exceeds_limit: '### için PO Numarası ###### karakter sınırını aşıyor.',
    po_number_duplicate: 'PO Numaraları her sipariş için benzersiz olmalıdır.',
    invalid_po_number: 'Lütfen geçerli bir PO numarası gir',
    invalid_page_comment: 'Lütfen geçerli bir Yorum gir',
    products_not_available: 'Sepetinde seçilen gönderim tarihine kadar müsait olmayacak ürünler var.',
    already_submitted: 'Bu sipariş zaten gönderildi.',
    already_review: 'Bu siparişi temsilcine zaten gönderdin, gözden geçirilmesi gerekiyor.',
    shared_with: 'Belge bir bayii ile paylaşıldı. Sadece okunabilir, gönderilemez',
    dealer_submit: 'Belge temsilcinin gözden geçirmesini bekliyor ve gönderilemez',
    page: 'Sayfa',
    shipment: 'Gönderim',
    external_field: 'Lütfen şunun için ### seç:',
    credit_hold_explanation: 'Şu anki hesap durumunla bir sipariş oluşturabilirsin ama onu gönderemezsin. Lütfen Kredi Yöneticinle iletişim kur.',
    catalog_min_failure: 'Bu katalog için en az ### birim olması gerekir.',
    catalog_max_failure: 'Bu katalog için ### birim limiti aşıldı.',
    catalog_min_amount_failure: 'Bu katalog için minimum toplam sipariş tutarı ###.',
    catalog_max_amount_failure: 'Bu katalog için maksimum toplam sipariş tutarı ###.',
    catalog_price_group_failure: 'Eksik fiyat grubu nedeniyle sipariş verilememektedir.',
    catalog_requires_embellishments: 'Tüm öğeler kişiselleştirilmelidir.',
    catalog_requires_embellishments_customized: 'Tüm öğeler özelleştirilmelidir.',
    arrive_on_is_in_past: 'Gönderme Tarihi Başlangıcı ### için geçmişte. Lütfen bunu bugün ya da gelecekte olacak şekilde güncelle.',
    shipping_account_number_invalid: 'Lütfen geçerli bir gönderim hesap numarası girin',
    document_renamed: 'Sipariş tekrar adlandırıldı.',
    document_could_not_be_renamed: 'Sipariş tekrar adlandırılamadı.',
    has_placeholder_allocations: 'Bazı ürünler talep ettiğiniz gönderim tarihine kadar mevcut değil. Devam edebilmeniz için bu sorunun çözülmesi gerekiyor.',
    shipment_min_amount_failure: 'Her gönderi için minimum toplam tutar ###',
    shipment_min_quantity_failure: 'Her gönderi için minimum toplam ### birim olmalı.',
    shipment_max_amount_failure: 'Gönderi başına maksimum toplam tutar ###',
    shipment_max_quantity_failure: 'Bir gönderide en fazla ### birim olabilir.',
    order_has_shipments_with_state_restrictions: 'Siparişin eyalet kısıtlamalı gönderileri var',
    shipment_summary: 'Gönderim Özeti',
    choose_location: 'Konum Seç',
    save_order_title: 'Mevcut siparişini kaydetmek istiyor musun?',
    save_order_msg: 'Şu anda ayrı bir siparişin açık. Bu siparişi taslak olarak kaydedip bunu açmak istiyor musun?',
  }),
);

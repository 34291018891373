define(
  ({
    new_order_document: 'Elastic 注文',
    your_order_document: 'ご注文',
    no_quantities: '注文を送信するには1つ以上の数量を選択する必要があります。',
    no_customer_number: '請求先住所を選択してください: ',
    no_location_number: '発送先住所を選択してください: ',
    no_arrival_date: '発送日時を選択してください:',
    no_cancel_date: 'キャンセル日時を選択してください:',
    invalid_cancel_date: '有効なキャンセル日時を選択してください。',
    invalid_start_ship_date: '以下の有効な発送日時を選択してください:',
    no_po_number: '注文番号を入力してください:',
    po_number_exceeds_limit: '###の注文番号が######文字の制限を超えています。',
    po_number_duplicate: '注文番号は他と異なるものにする必要があります。',
    invalid_po_number: '有効な注文番号を入力してください',
    invalid_page_comment: '有効なコメントを入力してください',
    products_not_available: '選択された配送日までに入荷できない商品がカートにあります。',
    already_submitted: 'この注文はすでに送信済みです。',
    already_review: 'この注文を担当者に既に提出しています。確認が必要です。',
    shared_with: 'このドキュメントを販売業者と共有しました。これは読み取り専用で、送信することができません',
    dealer_submit: 'このドキュメントは担当者による確認待ちの状態です。送信することはできません',
    page: 'ページ',
    shipment: '発送',
    external_field: '###を選択してください: ',
    credit_hold_explanation: '現在のアカウントステータスでは、注文の作成はできますが、送信することはできません。クレジット管理者にお問い合わせください。',
    catalog_min_failure: 'このカタログには個数を少なくとも###個含めてください。',
    catalog_max_failure: 'このカタログの個数の上限である###個を超過しないでください。',
    catalog_min_amount_failure: 'このカタログの最低注文金額は###です。',
    catalog_max_amount_failure: 'このカタログのご注文金額の上限は###です。',
    catalog_price_group_failure: '価格グループが不足しているため、注文できません。',
    catalog_requires_embellishments: '必ずすべてのアイテムをパーソナライズしてください。',
    catalog_requires_embellishments_customized: '必ずすべてのアイテムをカスタマイズしてください。',
    arrive_on_is_in_past: '###の出荷予定日が過去の日付になっています。予定日を本日または未来の日付に更新してください。',
    shipping_account_number_invalid: '有効な発送先アカウント番号を入力してください',
    document_renamed: '注文名が変更されました。',
    document_could_not_be_renamed: '注文名を変更できませんでした。',
    has_placeholder_allocations: 'リクエストされた配送日に配送できないアイテムがいくつかあります。操作を続けるんは、この問題を解決する必要があります。',
    shipment_min_amount_failure: '発送ごとの最低合計金額は###です',
    shipment_min_quantity_failure: '発送ごとの最低合計数量は###ユニットです。',
    shipment_max_amount_failure: '発送ごとの最大合計額は###です',
    shipment_max_quantity_failure: '発送ごとの最大合計数量は###ユニットです。',
    order_has_shipments_with_state_restrictions: '注文には州の制限付きの発送があります',
    shipment_summary: '発送概要',
    choose_location: '場所を選択する',
    save_order_title: '現在の注文を保存しますか？',
    save_order_msg: '現在、別の注文が開いています。この注文を下書きとして保存して開きますか？',
  }),
);

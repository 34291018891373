define(
  ({
    app_name: 'Elastic',
    none: 'Geen',
    select: 'selecteren…',
    cancel: 'Annuleren',
    drop_ship_instructions: 'Alleen nationale verzending. Geen verzending naar APOs of FPOs',
    product_data_sheet_tab_technology: 'Technologie',
    product_data_sheet_tab_overview: 'Productbeschrijving',
    product_data_sheet_tab_details: 'Details',
    cart_submit_order_dealer: 'Bestelling plaatsen',
    cart_submit_order_rep: 'Bestelling plaatsen',
    cart_submit_order: 'Bestelling verzenden',
    erp_order_number: 'ERP-bestelling #',
    sap_order_number: 'SAP-bestelling #',
    nda_explanation: 'NDA-uitleg.',
    terms_and_conditions_description: 'Geef hieronder aan of u de <a href="###" target="_blank">Algemene voorwaarden</a> hebt gelezen en begrepen',
    discounted_total: 'Totaalbedrag met korting',
    upcharge: 'Extra kosten',
    dont_show_me_this_again: 'Niet meer weergeven.',
    okay: 'Oké',
    arrival_date: 'Startdatum verzending',
    sso_message: 'Extern inloggen',
    alt_sso_message: 'Alternatieve externe aanmelding',
    alt_login_title: 'Inloggen',
    prebook: 'Prebook',
    ats: 'ATS',
    alternate_images: 'Alternatieve afbeeldingen',
    choose: 'Kiezen',
    new_version_available_modal: 'Nieuwe versie beschikbaar',
    new_version_available_message: 'Er is een nieuwe versie van de applicatie beschikbaar',
    new_version_reload: 'Nieuwe versie laden',
    new_version_snooze: 'Later herinneren',
    new_version_save_and_reload: 'Mijn werk opslaan en opnieuw laden',
    popup_may_be_suppressed_title: 'Betalingspagina geopend',
    popup_may_be_suppressed_description: 'Er is een betaalpagina voor een creditcard geopend. Als u de pagina niet ziet, controleer dan in de adresbalk van uw browser of er een pop-up is geblokkeerd. Geef in dat geval toestemming voor de pop-up.',
    popup_blocked_title: 'Pop-up geblokkeerd',
    popup_blocked_description: 'Het nieuwe venster voor het invoeren van betaalgegevens is geblokkeerd. Controleer uw browserinstellingen en -extensies en probeer het opnieuw. Als het probleem aanhoudt, kunt u uw bestelling opslaan en Elastic in een andere browser openen. Elastic werkt het beste in recente versies van Google Chrome.',
    pay_by_credit_card: 'Betalen met creditcard',
    do_you_want_to_pay_by_credit_card: 'Wilt u met creditcard betalen?',
    pay_by_credit_card_no: 'Nee',
    pay_by_credit_card_yes: 'Ja',
    cancel_order_submission_and_payment: 'Bestelling en betaling annuleren',
    product_reference_short: 'REF: %{reference}',
    product_reference_long: 'SKU vorig jaar: %{reference}',
    variation_code: 'Variatiecode',
    pending_orders: 'Bestellingen in behandeling',
    freight_discount_message: 'Items in deze zending komen in aanmerking voor korting op de verzendkosten van het %{sales_program}',
    partial_freight_discount_message: 'Kies een verzendmethode voor items in deze zending die niet in aanmerking komen voor de verzendkorting van het %{sales_program}',
    non_qualifying_items_ship_by_default_method: 'Items die niet in aanmerking komen voor het %{sales_program} worden verzonden via de standaardmethode',
    export_pdf: 'PDF exporteren',
    ots: 'OTS',
    terms_and_conditions: 'Algemene voorwaarden',
    accept: 'Accepteren',
    back_to_orders: 'Terug naar Bestellingen',
    back_to_payments: 'Terug naar Betalen',
    back_to_shipments: 'Terug naar Zendingen',
    required: 'Vereist',
    send_request: 'verzoek verzenden',
    should_not_empty: 'mag niet leeg zijn',
    should_be_number: 'moet een nummer zijn',
    incorrect_value: 'onjuiste waarde',
    incorrect_phone: 'telefoonnummer moet internationale indeling hebben +xx',
    board_riders_description: 'Boardriders, Inc., is een toonaangevend bedrijf op het gebied van extreme sporten en lifestyle dat merkkleding, -schoenen en accessoires ontwerpt, produceert en distribueert voor Boardriders wereldwijd. De kleding- en schoenenmerken van het bedrijf staan voor een ongedwongen levensstijl voor mensen die jong van geest zijn en een passie voor extreme buitensporten hebben.',
    region_error_message: 'moet regio selecteren',
    country_error_message: 'moet land selecteren',
    state_error_message: 'moet deelstaat/provincie selecteren',
    config_error_message: 'Er is een fout opgetreden bij het openen van dit formulier vanwege ontbrekende beheerinstellingen.',
    success: 'Geslaagd',
    error: 'Fout',
    invalid_phone_number: 'Ongeldig telefoonnummer',
    invalid_email: 'Ongeldig e-mailadres',
    submit: 'Verzenden',
    success_message: 'Uw informatie is verzonden',
    error_message: 'Er trad een fout op tijdens het verzenden van uw informatie. Probeer het opnieuw of neem contact op met de klantenservice als het probleem aanhoudt.',
    no_schema: 'Deze functie is niet geconfigureerd',
    reset: 'Resetten',
    quick_entry_table_header_shipment_b2c: 'Verzending',
    enter_quantities_segmented_control_b2c_by_shipment: 'Per verzending',
    new_document_b2c_shipment: 'Verzending',
    boxed_order: 'Verpakte bestelling',
    boxed_order_explanation_title: 'Verpakte bestellingen',
    boxed_order_explanation: 'Meer dan een enkele doos aan een bestelling toevoegen, geldt als vermenigvuldiger. Ingevoerde hoeveelheden die worden vermenigvuldigd over het aantal ingevoerde dozen.',
    boxes: 'Dozen',
    units_per_box: 'Eenheden per doos',
    regional: 'Regionaal',
    purchase_order_tooltip: 'De ingevoerde waarde is niet geldig.',
    tournament_date_is_too_late: 'De datum moet op of voor %{date} vallen',
    tournament_date_is_too_early: 'De datum moet op of na %{date} zijn',
    page_notes_error: 'De ingevoerde waarde is niet geldig.',
    order_name_not_valid: 'De ingevoerde waarde is niet geldig.',
    internet_connection_lost: 'Internetverbinding verbroken',
    internet_connection_lost_builder_description: '<p>Je hebt geen verbinding met het internet. Maak opnieuw verbinding om door te gaan. Je werk is tijdens de laatste keer handmatig of automatisch opslaan opgeslagen.</p><p>Als je je zorgen maakt om gegevensverlies, kun je op de onderstaande knop klikken om een back-upbestand te downloaden en kun je dit bestand doorsturen naar <Link>support@elasticsuite.com</Link> om de bestelling te herstellen.</p>',
    internet_connection_lost_description: 'Je hebt momenteel geen verbinding met het internet. Herstel de verbinding om door te gaan.',
    download_backup_data_file: 'Back-upbestand downloaden',
    invalid_session: 'Ongeldige sessie',
    invalid_session_builder_description: '<p>Je hebt geen verbinding met het internet. Maak opnieuw verbinding om door te gaan. Je werk is tijdens de laatste keer handmatig of automatisch opslaan opgeslagen.</p><p>Als je je zorgen maakt om gegevensverlies, kun je op de onderstaande knop klikken om een back-upbestand te downloaden en kun je dit bestand doorsturen naar <Link>support@elasticsuite.com</Link> om de bestelling te herstellen.</p>',
    invalid_session_description: 'Je hebt momenteel geen verbinding met het internet. Herstel de verbinding om door te gaan.',
    submit_order_description: 'Uw bestelling wordt verzonden. Na verzending zal ze worden afgesloten en kan ze niet langer worden bewerkt. Merk op dat de bestelling in de cloud wordt opgeslagen.',
    all_sells_final: 'Alle bestellingen voor evenementen zijn definitief en niet terugneembaar. Zorg ervoor dat je bestelling juist is alvorens te verzenden. Bedankt voor je steun!',
    invalid_date: 'Ongeldige datum',
    date_placeholder: 'MM-DD-YYYY',
    recalculate_discounts: 'Kortingen opnieuw berekenen',
    available_as_of_unrestricted: 'Beschikbaar vanaf <date />',
    available_as_of: 'Beschikbaar vanaf <date />',
    order_too_large_not_saved: 'Bestelling is niet opgeslagen',
    order_too_large_description: 'Je bestelling overschrijdt de maximale limiet. Splits de bestelling op in meerdere bestellingen.',
    access_denied: 'Toegang Geweigerd',
    deep_link_description: 'Je hebt geen toestemming om de gedeelde link te openen. Neem contact op met je merkbeheerder.',
    close: 'Sluiten',
    sizes: 'Maten',
    revisions: 'Herzieningen',
    technology: 'Technologie',
    description: 'Omschrijving',
    failed_to_find_prices: 'Geen prijzen gevonden.',
    availability: 'Beschikbaarheid',
    available_by: 'Beschikbaar vanaf',
    total: 'Totaal',
    today: 'Vandaag',
    similar: 'Vergelijkbaar',
    complementary: 'Aanvullend',
    previous: 'Vorige',
    next: 'Volgende',
    add_to_shipment: 'Toevoegen aan verzending:',
    add_to_cart: 'Toevoegen aan winkelwagen',
    remove_from_cart: 'Verwijderen uit winkelwagen',
    units_bundled_singular_label: '%{units} unit gebundeld',
    catalog_status: 'Actieve producten',
    dropped: 'Vervallen',
    add_all: 'Alle variaties toevoegen',
    in_cart: 'In winkelwagen',
    not_in_cart: 'Niet in winkelwagen',
    add: 'Toevoegen',
    remove: 'Verwijderen',
  }),
);

define(
  ({
    new_order_document: 'Encomenda Elastic',
    your_order_document: 'A sua encomenda',
    no_quantities: 'Deve selecionar a quantia de pelo menos 1 unidade para submeter uma encomenda.',
    no_customer_number: 'Por favor, selecione uma morada de faturação para ',
    no_location_number: 'Por favor, selecione uma morada de envio para ',
    no_arrival_date: 'Por favor, selecione uma data de início de envio para',
    no_cancel_date: 'Por favor, selecione uma data de cancelamento para',
    invalid_cancel_date: 'Por favor, selecione uma data de cancelamento válida.',
    invalid_start_ship_date: 'Por favor, selecione uma data de início de envio para',
    no_po_number: 'Por favor, introduza um Nome da encomenda para',
    po_number_exceeds_limit: 'O número do pedido (PO) para ### excede o limite de ###### caracteres.',
    po_number_duplicate: 'Os números de caixa postal têm de ser únicos para cada encomenda.',
    invalid_po_number: 'Por favor, introduza um Nome da encomenda válido',
    invalid_page_comment: 'Por favor, introduza um comentário válido',
    products_not_available: 'Tem produtos no seu carrinho que não estarão disponíveis na data que selecionou para o envio.',
    already_submitted: 'Esta encomenda já foi submetida.',
    already_review: 'Já submeteu esta encomenda ao seu representante, a mesma tem de ser revista.',
    shared_with: 'O documento foi partilhado com um revendedor. É um documento apenas de leitura e não pode ser submetido',
    dealer_submit: 'O documento está com a revisão do seu representante pendente e não pode ser submetido',
    page: 'Página',
    shipment: 'Envio',
    external_field: 'Por favor, selecione ### para ',
    credit_hold_explanation: 'Com o estado atual da sua conta consegue criar uma encomenda, mas não poderá submetê-la. Por favor, contacte o seu gestor de crédito.',
    catalog_min_failure: 'Tem de haver pelo menos ### unidades para este catálogo.',
    catalog_max_failure: 'O limite de ### unidades para este catálogo foi ultrapassado.',
    catalog_min_amount_failure: 'O montante mínimo possível para encomendar deste catálogo é ###.',
    catalog_max_amount_failure: 'O montante máximo possível para encomendar deste catálogo é ###.',
    catalog_price_group_failure: 'A encomenda não pode ser feita por faltar um grupo de preços.',
    catalog_requires_embellishments: 'Todos os itens têm de ser personalizados.',
    catalog_requires_embellishments_customized: 'Todos os itens têm de ser personalizados.',
    arrive_on_is_in_past: 'A data de começo do envio está no passado à ###. Por favor, atualize-a para ocorrer hoje ou no futuro.',
    shipping_account_number_invalid: 'Por favor, introduza um número de conta de compras válido',
    document_renamed: 'Nome da encomenda alterada.',
    document_could_not_be_renamed: 'O nome da encomenda não pode ser alterado.',
    has_placeholder_allocations: 'Alguns itens estão indisponíveis na data de envio solicitada. Este problema tem de ser resolvido para poder continuar.',
    shipment_min_amount_failure: 'O valor total mínimo por envio é ###',
    shipment_min_quantity_failure: 'A quantidade mínima total por envio é de ### unidades.',
    shipment_max_amount_failure: 'O valor total máximo por envio é ###',
    shipment_max_quantity_failure: 'A quantidade total máxima por envio é de ### unidades.',
    order_has_shipments_with_state_restrictions: 'A encomenda tem envios com restrições de estado',
    shipment_summary: 'Resumo do envio',
    choose_location: 'Escolher Localização',
    save_order_title: 'Gostaria de guardar a sua encomenda atual?',
    save_order_msg: 'Tem uma encomenda separada aberta. Quer guardar esta encomenda como rascunho e abrir esta?',
  }),
);

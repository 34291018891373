define(
  ({
    new_order_document: 'Elastic-bestelling',
    your_order_document: 'Je bestelling',
    no_quantities: 'De geselecteerde hoeveelheid moet minimaal 1 zijn om een bestelling te kunnen verzenden.',
    no_customer_number: 'Selecteer een factuuradres voor ',
    no_location_number: 'Selecteer een verzendadres voor ',
    no_arrival_date: 'Selecteer een Startdatum verzending voor',
    no_cancel_date: 'Selecteer een Annuleringsdatum voor',
    invalid_cancel_date: 'Selecteer een geldige annuleringsdatum.',
    invalid_start_ship_date: 'Selecteer een geldige Startdatum voor verzending voor',
    no_po_number: 'Voer een geldig PO-nummer in voor',
    po_number_exceeds_limit: 'Het PO-nummer voor ### overschrijdt de limiet van ###### tekens.',
    po_number_duplicate: 'PO-nummers moeten uniek zijn voor elke bestelling.',
    invalid_po_number: 'Voer een geldig PO-nummer in',
    invalid_page_comment: 'Voer een geldige opmerking in',
    products_not_available: 'U hebt producten in uw winkelwagen die niet beschikbaar zijn voor uw geselecteerde verzenddatum.',
    already_submitted: 'Deze bestelling is al verzonden.',
    already_review: 'U hebt deze bestelling al naar uw vertegenwoordiger verzonden. Ze moet worden gecontroleerd.',
    shared_with: 'Het document is gedeeld met een dealer. Het is Alleen lezen en kan niet worden verzonden',
    dealer_submit: 'Het document wordt gecontroleerd door uw vertegenwoordiger en kan niet worden verzonden',
    page: 'Pagina',
    shipment: 'Verzending',
    external_field: 'Selecteer ### voor ',
    credit_hold_explanation: 'Met uw huidige accountstatus kunt u een bestelling aanmaken, maar kunt u deze niet verzenden. Neem contact op met uw Credit Manager.',
    catalog_min_failure: 'Moet ten minste ### eenheden hebben voor deze catalogus.',
    catalog_max_failure: 'De limiet van ### eenheden voor deze catalogus is overschreden.',
    catalog_min_amount_failure: 'Het minimumaantal eenheden voor de bestelling voor deze catalogus is ###.',
    catalog_max_amount_failure: 'Het maximale totale bestellingsbedrag voor deze catalogus is ###.',
    catalog_price_group_failure: 'Bestellingen kunnen vanwege een ontbrekende prijsgroep niet worden geplaatst.',
    catalog_requires_embellishments: 'Alle items moeten worden gepersonaliseerd.',
    catalog_requires_embellishments_customized: 'Alle items moeten worden aangepast.',
    arrive_on_is_in_past: 'De startdatum voor verzending ligt in het verleden voor ###. Werk deze datum bij naar vandaag of een datum in de toekomst.',
    shipping_account_number_invalid: 'Voer een geldig verzendaccountnummer in',
    document_renamed: 'Naam van bestelling is gewijzigd.',
    document_could_not_be_renamed: 'De naam van de bestelling kon niet worden gewijzigd',
    has_placeholder_allocations: 'Niet alle items zijn op gewenste verzenddatum beschikbaar. Dit probleem moet worden opgelost om door te kunnen gaan.',
    shipment_min_amount_failure: 'Het minimale totale bedrag per verzending is ###',
    shipment_min_quantity_failure: 'De minimale totale hoeveelheid per zending is ### stuks.',
    shipment_max_amount_failure: 'Het maximale totaalbedrag per zending is ###',
    shipment_max_quantity_failure: 'De maximale totale hoeveelheid per verzending is ### eenheden.',
    order_has_shipments_with_state_restrictions: 'De bestelling bevat zendingen met staatsbeperkingen',
    shipment_summary: 'Samenvatting van verzending',
    choose_location: 'Locatie kiezen',
    save_order_title: 'Wil je je huidige bestelling opslaan?',
    save_order_msg: 'Je hebt momenteel een andere bestelling openstaan. Wil je deze bestelling als concept opslaan en die openen?',
  }),
);

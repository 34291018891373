define({
  root: ({
    app_name: 'Elastic',
    none: 'None',
    select: 'select...',
    cancel: 'Cancel',
    drop_ship_instructions: 'Domestic shipping only. No shipping to APOs or FPOs.',
    product_data_sheet_tab_technology: 'Technology',
    product_data_sheet_tab_overview: 'Overview',
    product_data_sheet_tab_details: 'Details',
    cart_submit_order_dealer: 'Place Order',
    cart_submit_order_rep: 'Place Order',
    cart_submit_order: 'Submit Order',
    erp_order_number: 'ERP Order #',
    sap_order_number: 'SAP Order #',
    nda_explanation: 'NDA explanation.',
    terms_and_conditions_description: 'Check below to indicate that you have read and agree to the <a href="###" target="_blank">Terms & Conditions</a>',
    discounted_total: 'Discounted Total',
    upcharge: 'Upcharge',
    dont_show_me_this_again: "Don't show me this again.",
    okay: 'Okay',
    arrival_date: 'Start Ship Date',
    dropship_additional_message: '',
    sso_message: 'External login',
    alt_sso_message: 'Alternative External login',
    alt_login_title: 'Login',
    prebook: 'Prebook',
    ats: 'ATS',
    alternate_images: 'Alternate Images',
    choose: 'Choose',
    new_version_available_modal: 'New Version Available',
    new_version_available_message: 'A new version of the application is available',
    new_version_reload: 'Load new version',
    new_version_snooze: 'Remind me later',
    new_version_save_and_reload: 'Save my work and reload',
    popup_may_be_suppressed_title: 'Payment Page Opened',
    popup_may_be_suppressed_description: 'A credit card payment page has been opened. If you do not see it, check the address bar of your browser for a blocked popup and allow it.',
    popup_blocked_title: 'Popup Blocked',
    popup_blocked_description: 'The new window for submitting payment was blocked. Check your browser settings and extensions and try again. If the problem persists, you can save your order and open Elastic in another browser. Elastic is best supported on recent versions of Google Chrome.',
    pay_by_credit_card: 'Pay by Credit Card',
    do_you_want_to_pay_by_credit_card: 'Do you want to pay by credit card?',
    pay_by_credit_card_no: 'No',
    pay_by_credit_card_yes: 'Yes',
    cancel_order_submission_and_payment: 'Cancel order submission and payment',
    product_reference_short: 'REF: %{reference}',
    product_reference_long: 'Prior Year SKU: %{reference}',
    variation_code: 'Variation code',
    pending_orders: 'Pending Orders',
    freight_discount_message: 'Items in this shipment qualify for the %{sales_program} shipping discount',
    partial_freight_discount_message: 'Choose a shipping method for items in this shipment that do not qualify for the %{sales_program} shipping discount',
    non_qualifying_items_ship_by_default_method: 'Items that did not qualify for the %{sales_program} will ship via the default method',
    export_pdf: 'Export PDF',
    ots: 'OTS',
    terms_and_conditions: 'Terms and Conditions',
    accept: 'Accept',
    back_to_orders: 'Back to Orders',
    back_to_payments: 'Back to Payments',
    back_to_shipments: 'Back to Shipments',
    required: 'Required',
    send_request: 'send request',
    should_not_empty: 'should not be empty',
    should_be_number: 'should be a number',
    incorrect_value: 'incorrect value',
    incorrect_phone: 'phone number should match international format +xx',
    board_riders_description: 'Boardriders, Inc., is a leading action sports and lifestyle company that designs, produces and distributes branded apparel, footwear and accessories for Boardriders around the world. The Company apparel and footwear brands, represent a casual lifestyle for young-minded people who are inspired by a passion for outdoor action sports.',
    region_error_message: 'should select region',
    country_error_message: 'should select country',
    state_error_message: 'should select state/province',
    config_error_message: 'There was an error opening this form because of missing administration settings.',
    success: 'Success',
    error: 'Error',
    invalid_phone_number: 'Invalid phone number',
    invalid_email: 'Invalid email',
    submit: 'Submit',
    success_message: 'Your information was successfully submitted',
    error_message: 'There was an error submtting your information. Try again or contact support if the problem persists.',
    no_schema: 'This feature has not been configured',
    reset: 'Reset',
    quick_entry_table_header_shipment_b2c: 'Shipment',
    enter_quantities_segmented_control_b2c_by_shipment: 'By Shipment',
    new_document_b2c_shipment: 'Shipment',
    boxed_order: 'Boxed Order',
    boxed_order_explanation_title: 'Boxed Orders',
    boxed_order_explanation: 'Adding more than a single box to an order acts as a quantity multiplier. Quantities entered will be duplicated across the # of boxes entered.',
    boxes: 'Boxes',
    units_per_box: 'Units per Box',
    regional: 'Regional',
    purchase_order_tooltip: 'The value entered is not valid.',
    tournament_date_is_too_late: 'Date must be on or before %{date}',
    tournament_date_is_too_early: 'Date must be on or after %{date}',
    page_notes_error: 'The value entered is not valid.',
    order_name_not_valid: 'The value entered is not valid.',
    internet_connection_lost: 'Internet Connection Lost',
    internet_connection_lost_builder_description: '<p>You are not connected to the internet. Please reconnect to continue. Your work has been saved to the last manual or auto-save.</p><p>If you are concerned about loss of data, please click the button below to download a backup data file, and forward the file to <Link>support@elasticsuite.com</Link> to reinstate your order.</p>',
    internet_connection_lost_description: 'You are not currently connected to the internet. Please reconnect to continue.',
    download_backup_data_file: 'Download Backup Data File',
    invalid_session: 'Invalid Session',
    invalid_session_builder_description: '<p>You are not connected to the internet. Please reconnect to continue. Your work has been saved to the last manual or auto-save.</p><p>If you are concerned about loss of data, please click the button below to download a backup data file, and forward the file to <Link>support@elasticsuite.com</Link> to reinstate your order.</p>',
    invalid_session_description: 'You are not currently connected to the internet. Please reconnect to continue.',
    submit_order_description: 'Your order is about to be submitted. Once submitted, it will be locked and no longer editable. Please note that the order will be saved to the cloud.',
    all_sells_final: 'All event orders are final and not eligible to be returned. Please make sure you have reviewed your order before submitting. Thank you for your support!',
    invalid_date: 'Invalid Date',
    date_placeholder: 'MM-DD-YYYY',
    recalculate_discounts: 'Recalculate Discounts',
    available_as_of_unrestricted: 'Available as of <date />',
    available_as_of: 'Available as of <date />',
    order_too_large_not_saved: 'Order Not Saved',
    order_too_large_description: 'The order exceeds maximum limits. Split the order into multiple orders.',
    access_denied: 'Access Denied',
    deep_link_description: 'You do not have permission to access the shared link. Contact your brand administrator.',
    close: 'Close',
    sizes: 'Sizes',
    revisions: 'Revisions',
    technology: 'Technology',
    description: 'Description',
    failed_to_find_prices: 'Failed to find prices.',
    availability: 'Availability',
    available_by: 'Available By',
    total: 'Total',
    today: 'Today',
    similar: 'Similar',
    complementary: 'Complementary',
    previous: 'Previous',
    next: 'Next',
    add_to_shipment: 'Add to Shipment:',
    add_to_cart: 'Add to Cart',
    remove_from_cart: 'Remove from Cart',
    units_bundled_singular_label: '%{units} Unit Bundled',
    catalog_status: 'Active Products',
    purchase_order: 'PO#',
    no_po_number: 'Please enter a %{po} for shipment',
    dropped: 'Dropped',
    add_all: 'Add All Variations',
    in_cart: 'In Cart',
    not_in_cart: 'Not In Cart',
    add: 'Add',
    remove: 'Remove',
    custom_assets: 'Custom Assets',
    apply_discounts: 'View Discounts',
    view_discounts: 'View Discounts',
    purchase_rule: "Purchase Rule",
    minimum_qty: "Minimum Qty",
  }),
  'zh-tw': true,
  zh: true,
  ja: true,
  fr: true,
  de: true,
  ko: true,
  it: true,
  cs: true,
  tr: true,
  pl: true,
  fi: true,
  hu: true,
  sk: true,
  'en-gb': true,
  nb: true,
  es: true,
  nl: true,
  pt: true,
  ru: true,
  sv: true,
});

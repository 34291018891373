define(
  ({
    new_order_document: '새 유동적 주문',
    your_order_document: '내 주문',
    no_quantities: '주문을 제출하려면 최소 1개를 선택해야 합니다.',
    no_customer_number: '의 청구 주소를 선택하세요.',
    no_location_number: '의 배송 주소를 선택하세요.',
    no_arrival_date: '의 배송 시작일을 선택하세요.',
    no_cancel_date: '의 취소일을 선택하세요.',
    invalid_cancel_date: '유효한 취소일을 선택하세요.',
    invalid_start_ship_date: '의 유효한 배송 시작일을 선택하세요.',
    no_po_number: '의 PO 번호를 입력하세요.',
    po_number_exceeds_limit: '###의 PO 번호가 ######자 한도를 초과합니다.',
    po_number_duplicate: 'PO 번호는 각 주문마다 달라야 합니다.',
    invalid_po_number: '유효한 PO 번호를 입력하세요',
    invalid_page_comment: '유효한 댓글을 입력하세요',
    products_not_available: '선택한 배송일까지 구입할 수 없는 제품이 카트에 있습니다.',
    already_submitted: '이 주문은 이미 제출되었습니다.',
    already_review: '이미 담당자에게 이 주문을 제출했습니다. 검토가 필요합니다.',
    shared_with: '문서가 중개인과 공유되었습니다. 읽기 전용이므로 전송할 수 없습니다.',
    dealer_submit: '문서가 대리인의 검토를 기다리고 있으므로 전송할 수 없습니다.',
    page: '페이지',
    shipment: '배송',
    external_field: '### 선택',
    credit_hold_explanation: '현재 계정 상태로는 주문을 생성할 수는 있지만, 제출할 수는 없습니다. 크레딧 매니저에게 문의하세요.',
    catalog_min_failure: '이 카탈로그에는 최소한 ### 단위를 포함해야 합니다.',
    catalog_max_failure: '이 카탈로그의 한도인 ### 단위를 초과했습니다.',
    catalog_min_amount_failure: '이 카탈로그에 대한 최소 총액은 ###입니다.',
    catalog_max_amount_failure: '이 카탈로그에 대한 최대 총액은 ###입니다.',
    catalog_price_group_failure: '가격 그룹이 누락되어 주문할 수 없습니다.',
    catalog_requires_embellishments: '모든 항목을 개인화해야 합니다.',
    catalog_requires_embellishments_customized: '모든 항목을 맞춤 설정해야 합니다.',
    arrive_on_is_in_past: '배송 시작일이 지난 ###입니다. 오늘 이후로 업데이트 해주세요.',
    shipping_account_number_invalid: '유효한 배송 계정 번호를 입력하세요',
    document_renamed: '주문 이름 변경됨.',
    document_could_not_be_renamed: '주문 이름을 변경할 수 없습니다.',
    has_placeholder_allocations: '요청한 배송일까지 일부 항목을 사용할 수 없습니다. 계속하려면 이 문제를 해결해야 합니다.',
    shipment_min_amount_failure: '배송당 최소 총 금액은 ###입니다.',
    shipment_min_quantity_failure: '배송당 최소 총 수량은 ###개입니다.',
    shipment_max_amount_failure: '배송당 최대 총 금액은 ###입니다.',
    shipment_max_quantity_failure: '배송당 최대 총 수량은 ###개입니다.',
    order_has_shipments_with_state_restrictions: '주문에 주 제한이 있는 배송이 있습니다',
    shipment_summary: '배송 요약',
    choose_location: '위치 선택',
    save_order_title: '현재 주문을 저장하시겠습니까?',
    save_order_msg: '현재 별도의 주문이 열려 있습니다. 이 주문을 초안으로 저장하고 여시겠습니까?',
  }),
);
